import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { StorageService } from 'src/app/core/services/utils/storage.service';

import { FilterModel } from 'src/app/modules/shared/models/filters/filter.model';

@Injectable({
  providedIn: 'root'
})
export class FilterArchiveLeadsDataService {
  filterChanged$: Observable<FilterModel>;

  filterSelectedFinalChanged$: Observable<FilterModel>;

  private filterSubject: BehaviorSubject<FilterModel>;

  private filterSelectedSubject: BehaviorSubject<FilterModel>;

  private filterSelectedFinalSubject: BehaviorSubject<FilterModel>;

  constructor(private storageService: StorageService) {
    this.initialize();
  }

  setFilter(filter: FilterModel): void {
    this.filterSubject.next(filter);
  }

  getFilter(): FilterModel {
    return this.filterSubject.getValue();
  }

  setSelectedFilter(filter: FilterModel): void {
    if (filter?.counties?.length) {
      this.filterSelectedSubject.next({ ...this.getSelectedFilter(), counties: filter.counties });
    } else if (filter?.counties?.length === 0) {
      this.filterSelectedSubject.next({ ...this.getSelectedFilter(), counties: [] });
    } else if (filter?.notificationTypes?.length) {
      this.filterSelectedSubject.next({
        ...this.getSelectedFilter(),
        notificationTypes: filter.notificationTypes,
      });
    } else if (filter?.notificationTypes?.length === 0) {
      this.filterSelectedSubject.next({
        ...this.getSelectedFilter(),
        notificationTypes: [],
      });
    } else if (filter?.propertyTypes?.length) {
      this.filterSelectedSubject.next({
        ...this.getSelectedFilter(),
        propertyTypes: filter.propertyTypes,
      });
    } else if (filter?.propertyTypes?.length === 0) {
      this.filterSelectedSubject.next({
        ...this.getSelectedFilter(),
        propertyTypes: [],
      });
    } else if (filter?.statusTypes?.length) {
      this.filterSelectedSubject.next({
        ...this.getSelectedFilter(),
        statusTypes: filter.statusTypes,
      });
    } else if (filter?.statusTypes?.length === 0) {
      this.filterSelectedSubject.next({
        ...this.getSelectedFilter(),
        statusTypes: [],
      });
    } else if (filter?.filterByRange?.from) {
      this.filterSelectedSubject.next({
        ...this.getSelectedFilter(),
        filterByRange: filter.filterByRange,
      });
    } else if (!filter?.filterByRange?.from) {
      this.filterSelectedSubject.next({
        ...this.getSelectedFilter(),
        filterByRange: null,
      });
    }
  }

  getSelectedFilter(): FilterModel {
    return this.filterSelectedSubject.getValue();
  }

  setSelectedFinalFilter(filter: FilterModel): void {
    this.storageService.set<FilterModel>('archiveLeadsFinalFilter', filter);

    this.filterSelectedFinalSubject.next(filter);
  }

  resetFilter() {
    this.filterSubject.next(null);
    this.filterSelectedSubject.next(null);
    this.filterSelectedFinalSubject.next(null);
  }

  private initialize(): void {
    this.filterSubject = new BehaviorSubject<FilterModel>(null);
    this.filterChanged$ = this.filterSubject.asObservable();

    this.filterSelectedSubject = new BehaviorSubject<FilterModel>(null);

    this.filterSelectedFinalSubject = new BehaviorSubject<FilterModel>(null);
    this.filterSelectedFinalChanged$ = this.filterSelectedFinalSubject.asObservable();
  }
}
