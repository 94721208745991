import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule } from '@ionic/angular';

import { HttpInterceptorProviders } from './interceptors';

import { SideMenuProfileComponent } from './components/side-menu-profile/side-menu-profile.component';

import { AppRoutingModule } from '../app-routing.module';

@NgModule({
  imports: [CommonModule, HttpClientModule, IonicModule, AppRoutingModule],
  providers: [HttpInterceptorProviders],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('You should import core module only in the root module');
    }
  }
}
