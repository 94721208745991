import { Component, OnInit } from '@angular/core';


import { Platform } from '@ionic/angular';

import { ScreenOrientation, OrientationType } from '@capawesome/capacitor-screen-orientation';

import { SplashScreen } from '@capacitor/splash-screen';
import { App } from '@capacitor/app';

import * as Sentry from '@sentry/browser';

import { UserModel } from 'src/app/core/models/user.model';
import { FilterModel } from './modules/shared/models/filters/filter.model';

import { checkIsNativePlatformFunction } from 'src/app/modules/shared/functions/check-is-native-platform.function';

import { FirebaseAuthService } from 'src/app/core/services/firebase/firebase-auth.service';
import { UserDataService } from 'src/app/core/services/data/user-data.service';
import { StorageService } from './core/services/utils/storage.service';
import { AppStateService } from './modules/services/utils/app-state.service';
import { FilterAlertsDataService } from './modules/services/data/filter-alerts-data.service';
import { FilterLeadsDataService } from './modules/services/data/filter-leads-data.service';
import { FilterSoldLeadsDataService } from './modules/services/data/filter-sold-leads-data.service';

import { environment } from 'src/environments/environment';
import { FilterArchiveAlertsDataService } from './modules/services/data/filter-archive-alerts-data.service';
import { FilterArchiveLeadsDataService } from './modules/services/data/filter-archive-leads-data.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  appEnvironment = environment.production
    ? 'Production Build'
    : 'Development Build';

  constructor(
    private platform: Platform,
    private firebaseAuthService: FirebaseAuthService,
    private userDataService: UserDataService,
    private storageService: StorageService,

    private appStateService: AppStateService,
    private filterAlertsDataService: FilterAlertsDataService,
    private filterArchiveAlertsDataService: FilterArchiveAlertsDataService,
    private filterLeadsDataService: FilterLeadsDataService,
    private filterArchiveLeadsDataService: FilterArchiveLeadsDataService,
    private filterSoldLeadsDataService: FilterSoldLeadsDataService
  ) {}

  ngOnInit(): void {
    this.platform.ready().then(()=>this.initializeApp());
  }

  private async initializeApp(): Promise<void> {
    const [
      user,
      isIntroductionShowed,
      isNotificationPreferencesEnabled,
      isSignedIn,
      email,
      alertsFinalFilter,
      archiveAlertsFinalFilter,
      leadsFinalFilter,
      archiveLeadsFinalFilter,
      soldLeadsFinalFilter,
    ] = await Promise.all([
      this.storageService.get<UserModel>('user'),
      this.storageService.get<boolean>('isIntroductionShowed'),
      this.storageService.get<boolean>('isNotificationPreferencesEnabled'),
      this.storageService.get<boolean>('isSignedIn'),
      this.storageService.get<string>('email'),
      this.storageService.get<FilterModel>('alertsFinalFilter'),
      this.storageService.get<FilterModel>('archiveAlertsFinalFilter'),
      this.storageService.get<FilterModel>('leadsFinalFilter'),
      this.storageService.get<FilterModel>('archiveLeadsFinalFilter'),
      this.storageService.get<FilterModel>('soldLeadsFinalFilter'),
    ]);

    if (user) {
      this.userDataService.setUser(user);
    }

    if (isIntroductionShowed) {
      this.userDataService.setIntroduction(isIntroductionShowed);
    }

    this.userDataService.setNotificationPreferences(isNotificationPreferencesEnabled ?? true);

    if (isSignedIn) {
      this.userDataService.setSignIn(isSignedIn);
    }

    if (email) {
      this.userDataService.setSignInEmail(email); //Remember email when user logs out
    }

    this.firebaseAuthService.setFirebaseAuth();

    if (alertsFinalFilter) {
      this.filterAlertsDataService.setSelectedFinalFilter(alertsFinalFilter);
    }

    if (archiveAlertsFinalFilter) {
      this.filterArchiveAlertsDataService.setSelectedFinalFilter(archiveAlertsFinalFilter);
    }

    if (leadsFinalFilter) {
      this.filterLeadsDataService.setSelectedFinalFilter(leadsFinalFilter);
    }

    if (archiveLeadsFinalFilter) {
      this.filterArchiveLeadsDataService.setSelectedFinalFilter(archiveLeadsFinalFilter);
    }

    if (soldLeadsFinalFilter) {
      this.filterSoldLeadsDataService.setSelectedFinalFilter(soldLeadsFinalFilter);
    }

    if (checkIsNativePlatformFunction()) {
      //run only on native platforms
      await ScreenOrientation.lock({ type: OrientationType.PORTRAIT });
    }

    this.checkDeviceHeight();

    this.setSentryScope();

    this.initializeListener();

    setTimeout(() => {
      SplashScreen.hide();
    }, 2000);
  }

  private checkDeviceHeight(): void {
    const deviceHeight = this.platform.height();

    deviceHeight > 700
      ? this.appStateService.setOlderDevice(false)
      : this.appStateService.setOlderDevice(true);
  }

  private setSentryScope(): void {
    if (!checkIsNativePlatformFunction()) {
      return; // Sentry works only on Native platforms
    }

    Sentry.configureScope((scope) => {
      scope.setTag('firebaseProjectId', environment.firebaseConfig.projectId);
    });
  }

  private initializeListener(): void {
    App.addListener('appStateChange', async ({ isActive }) => {
      console.log('appStateChange listener',isActive);
      
      if (isActive) {
        // Ensure download occurs while the app is active, or download may fail
        
      }  
      if (!isActive) {
        // Activate the update when the application is sent to background
        await SplashScreen.show();
        try {
        } catch (e) {
           // log error
        } finally {
          // Hide the splash screen again if something went wrong
          await SplashScreen.hide();
        }
      }
      // this.appStateService.setAppState(!isActive);
    });
  }
}
